.red {
  color: #e81212 !important;
}

.gray {
  color: #38438f !important;
}

.rebate_payment_history {
  .calendar_content {
    margin-bottom: 48px;
  }

  .content_box {
    h2 {
      display: inline-block;
      font-size: 32px;
      font-weight: 500;
      line-height: 1.38;
      color: $primary-purple;
      vertical-align: -webkit-baseline-middle;
      @include rtl-sass-prop(margin-right, margin-left, 44px);
    }

    .datepicker {
      display: grid;
      grid-template-columns: 85% calc(10% - 32px);
      column-gap: 32px;
      margin-bottom: 40px;

      .form_button {
        @include rtl-sass-value(text-align, right, left);
      }
    }

    .select_tab {
      margin-bottom: 30px;
      float: right;

      ul {
        display: inline-block;
      }
    }

    .select_table_box li {
      display: none;

      .page_box {
        width: 100%;
        margin: 38px 0px;
      }

      &.active {
        display: block;
      }
    }
  }

  .cancelWithdrawDialog {
    text-align: center;

    h5 {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 812px) {
  .rebate_payment_history {
    .calendar_content .bottom {
      grid-template-columns: 80% calc(10% - 32px);

      /deep/ .calendar {
        @include rtl-sass-prop(margin-left, margin-right, 0);
        margin-bottom: 25px;
      }

      .form_button {
        text-align: center;

        .el-button {
          margin-top: 15px;
        }
      }
    }

    /deep/.el-dialog__body {
      padding: 80px 30px 60px;
    }
  }
}

@media (max-width: 550px) {
  .rebate_payment_history {
    .calendar_content {
      margin-bottom: 30px;
    }

    .content_box .datepicker {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      row-gap: 25px;

      .form_button {
        margin-top: 10px;
        text-align: center !important;
      }
    }

    .content_box .select_tab {
      text-align: center;
    }
  }
}
